import React from 'react'
import {Link} from 'gatsby'
import ReactWOW from 'react-wow'
import starIcon from '../../assets/images/star-icon.png'
import capteurs from '../../assets/images/home/capteurs.png'
import wispeek from '../../assets/images/home/wispeek.png'
import siteInternet from '../../assets/images/home/site-internet.png'
import nas4 from '../../assets/images/home/nas4.png'
import borne from '../../assets/images/home/borne.png'

const Produits = () => {
    return (
        <section className="solutions-area pt-100 pb-70">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="star" /> 
                        Nos Produits
                    </span>
                </div>
                <div className="row align-items-center pb-30">
                    <div className="col-lg-8 col-md-12 ptb-30">
                        <h2 className="subtitle">Wispeek : la plateforme intuitive SAAS</h2>
                        <p><span className='bold color-compliance'>Wispeek</span> permet de <span className='bold'>faire remonter et d’échanger des informations</span> au sein de votre organisation, de manière sécurisée. Un <span className='bold'>outil collaboratif et transversal</span>, qui optimise l’efficience opérationnelle, maintient le système d’information à jour et croise les données pour capitaliser les retours d’expérience autour de 5 modules.</p>
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <div className="project-start-image">
                            <img src={wispeek} alt="project" />
                            <Link to="https://www.wispeek.com" className="btn-second" target="_blank">
                                <i className="flaticon-right"></i> 
                                    Espace utilisateur <span></span>
                            </Link>
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                        <div className="about-content">
                            <div className="content ptb-30">
                                <span className="sub-title">
                                    <img src={starIcon} alt="star" /> 
                                     Wispeek : une solution modulable clé en main
                                </span>
                            </div>
                        </div>
                        <div className='row' id="home-products">
                            <p className='col-lg-4 col-md-6'><h4 className='color-compliance bold'><i class='bx bxs-extension bx-tada' ></i> Compliance :</h4> Ce service vous propose un dispositif <span className='bold'>d’alerte interne éthique</span> ainsi <span className='bold'>qu’un dispositif anti-corruption</span> destinés aussi bien à protéger l’ensemble de vos collaborateurs que la réputation de votre organisation.</p>
                            <p className='col-lg-4 col-md-6'><h4 className='color-qhse bold'><i class='bx bxs-extension bx-tada' ></i> QHSE | Qualité :</h4> Solution conçue pour centraliser, simplifier et maîtriser <span className='bold'>votre système de management QHSE</span> : collecte, partage, analyse, édition et archivage des données, afin d’alimenter l’écosystème des démarches de progrès continu.</p>
                            <p className='col-lg-4 col-md-6'><h4 className='color-food-safety bold'><i class='bx bxs-extension bx-tada' ></i> Food Safety Culture :</h4> Solution de <span className='bold'>mise en conformité</span> qui permet de maîtriser vos processus liés à la qualité et la sécurité alimentaire afin de <span className='bold'>certifier la traçabilité et la sécurité de vos produits et données</span>.</p>
                            <p className='col-lg-4 col-md-6'><h4 className='color-qvct bold'><i class='bx bxs-extension bx-tada' ></i> QVCT | DUERP :</h4> Capitalisez sur <span className='bold'>les feedbacks de vos équipes</span> autour des questions de bien-être au travail et d’expérience collaborateur pour renforcer votre <span className='bold'>prévention santé au travail</span>.</p>
                            <p className='col-lg-4 col-md-6'><h4 className='color-relext bold'><i class='bx bxs-extension bx-tada' ></i> Relation externe :</h4> Collectez et traitez efficacement les <span className='bold'>informations essentielles provenant de vos tiers</span> : réclamations clients, enquêtes de satisfaction, évaluations des tiers, fiches fournisseurs, etc.</p>
                            <p className='col-lg-4 col-md-6'><h4 className='color-sav bold'><i class='bx bxs-extension bx-tada' ></i> Service Client :</h4> 
                            Une équipe réactive pour une <span className='bold'>relation de proximité</span>.<br></br>
                            Un accompagnement personnalisé.<br></br>
                            Un <span className='bold'>déploiement rapide</span> de la solution.</p>
                        </div>
                        <ReactWOW delay='.1s' animation='fadeInRight'>
                            <div className="btn-box section-title pt-50">
                                <Link to="/wispeek" className="default-btn">
                                    <i className="flaticon-right"></i> 
                                    Découvrez Wispeek <span></span>
                                </Link>
                            </div>
                        </ReactWOW>
                    </div>
                </div>

                <div className="row align-items-center ptb-30">
                    <div className="col-lg-4 col-md-12">
                        <div className="project-start-image">
                            <img src={borne} alt="project" />
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-12">
                        <h2 className="subtitle">Tablettes, Bornes</h2>
                        <p>Fini les fiches papier et le crayon !<br></br>
                        Pour les entreprises et organisations qui souhaitent être accompagnées dans leur <span className='bold'>démarche de transformation digitale</span>, nous proposons à nos clients des <span className='bold'>bornes Wispeek couplées à notre solution digitale</span>.
                        Des bornes fixes ou mobiles adaptées pour recevoir des tablettes tactiles, mises à la disposition de vos collaborateurs et visiteurs afin de <span className='bold'>digitaliser vos processus métiers</span> (formulaires de non-conformité, d’audit, visiteur, etc.)</p>
                    </div>
                    <div className="col-lg-12 col-md-12">
                        <ReactWOW delay='.1s' animation='fadeInRight'>
                            <div className="btn-box section-title pt-50">
                                <Link to="/bornes" className="default-btn">
                                    <i className="flaticon-right"></i> 
                                    Découvrez nos bornes  <span></span>
                                </Link>
                            </div>
                        </ReactWOW>
                    </div>
                </div>
                
                <div className="row align-items-center ptb-30">
                    <div className="col-lg-8 col-md-12 bottom">
                        <h2 className="subtitle">Capteurs connectés</h2>
                        <p>Une gamme de <span className='bold'>capteurs autonomes</span> qui permettent de <span className='bold'>surveiller</span> tous vos espaces de travail ou recevant du public. Livré à l’unité ou en flotte, le capteur mesure le taux de CO2, la température, l’humidité relative et la pression atmosphérique, etc. et est <span className='bold'>connecté à une application pour le suivi de vos données</span>.<br></br>
                        Toujours dans notre optique de <span className='bold'>gestion des remontées d’informations</span>, les capteurs peuvent se connecter à <span className='bold color-compliance'>Wispeek</span> pour traiter les alertes par la mise en place et le suivi d’un plan d’actions.</p>
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <div className="project-start-image">
                            <img src={capteurs} alt="project" />
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                        <ReactWOW delay='.1s' animation='fadeInRight'>
                            <div className="btn-box section-title pt-50">
                                <Link to="/capteurs" className="default-btn">
                                    <i className="flaticon-right"></i> 
                                    Découvrez nos capteurs  <span></span>
                                </Link>
                            </div>
                        </ReactWOW>
                    </div>
                </div>

                <div className="row align-items-center ptb-30">
                    <div className="col-lg-4 col-md-12">
                        <div className="project-start-image">
                            <img src={siteInternet} alt="project" />
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-12 bottom">
                        <h2 className="subtitle">Site internet</h2>
                        <p>Partage d'information, transparence, site vitrine, site catalogue ou boutique en ligne … Nous avons la solution qui vous permettra de <span className='bold'>booster votre activité</span> !<br></br>
                        Nous vous accompagnons pour <span className='bold'>améliorer la visibilité de votre entreprise sur le web</span>.<br></br>
                        Profitez de notre expertise dans la <span className='bold'>création et la conception de sites internet professionnels personnalisés</span>.</p>
                    </div>
                    <ReactWOW delay='.1s' animation='fadeInRight'>
                        <div className="btn-box section-title pt-50">
                            <Link to="/site-internet" className="default-btn">
                                <i className="flaticon-right"></i> 
                                Découvrez nos offres  <span></span>
                            </Link>
                        </div>
                    </ReactWOW>
                </div>     

                <div className="row align-items-center pt-30">
                    <div className="col-lg-8 col-md-12">
                        <h2 className="subtitle">Service Informatique</h2>
                        <p>Pertes de données, piratages, blocages de vos logiciels et site internet… <span className='bold'>Sécurisez la sauvegarde de vos données</span> en diversifiant vos backups pour garantir le maintien de votre activité.<br></br>
                        Sauvegardez et partagez vos fichiers sur un <span className='bold'>NAS</span>, un appareil de stockage autonome qui peut se connecter à votre réseau professionnel.</p>
                        <p><span className='bold'>ERP et CRM</span> pour gérer l’ensemble de votre organisation (PME/TPE, indépendants). Nous vous accompagnons dans l’installation de votre logiciel en mettant à votre disposition un <span className='bold'>large panel de fonctionnalités personnalisables et évolutives</span>.</p>
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <div className="project-start-image">
                            <img src={nas4} alt="project" />
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                        <ReactWOW delay='.1s' animation='fadeInRight'>
                            <div className="btn-box section-title pt-50">
                                <Link to="/service-informatique" className="default-btn">
                                    <i className="flaticon-right"></i> 
                                    Découvrez nos services  <span></span>
                                </Link>
                            </div>
                        </ReactWOW>
                    </div>
                </div>

                  
            </div>
        </section>
    )
}

export default Produits;