import React from 'react'
import {Link} from 'gatsby'
import starIcon from '../../assets/images/star-icon.png'
import { useLastThreeBlog } from '../../lastThreeBlog' 
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const kebabCase = string => string
    .normalize("NFD").replace(/\p{Diacritic}/gu, "")
    .replace(/([a-z])([A-Z])/g, "$1-$2")
    .replace(/[\s_]+/g, '-')
    .toLowerCase()


const Blog = () => {
    const {lastThree} = useLastThreeBlog()
    //console.log("DataBlog", lastThree.nodes)
    return (
        <section className="blog-area pt-100 pb-70 bg-f1f8fb">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="blog" /> 
                        Seekreet Blog
                    </span>
                    <h2>Nos derniers articles</h2>
                    <p>Retrouvez nos actualités, nos guides et conseils</p>
                </div>
                <div className="row">
                    {
                        lastThree.nodes.map(node => (
                            <div className="col-lg-4 col-md-6">
                                <div className="single-blog-post">
                                    <div className="post-image" key={node.id}>
                                        <Link to={"/"+node.frontmatter.slug}>
                                            <GatsbyImage
                                                image={getImage(node.frontmatter.hero_image)}
                                                alt={node.frontmatter.hero_image_alt}
                                            />
                                        </Link>
                                    </div>
                                    <div className="post-content">
                                        <ul className="post-meta d-flex justify-content-between align-items-center">
                                            <li className={"default "+kebabCase(node.frontmatter.category)}>
                                                <Link to={"/cat/"+kebabCase(node.frontmatter.category)}>
                                                    {node.frontmatter.category}
                                                </Link>
                                            </li>  
                                            <li>
                                                <i className='bx bx-calendar'></i> {node.frontmatter.date}
                                            </li>
                                        </ul>
                                        <h3>
                                            <Link to={"/"+node.frontmatter.slug}>
                                                {node.frontmatter.title}
                                            </Link>
                                        </h3>
                                        <p className='time-to-read'>{"Temps de lecture : "+node.frontmatter.readTime}</p>
                                        <p>{node.frontmatter.teaser}</p>
                                        <div className='follow-link'>
                                            <Link to={"/"+node.frontmatter.slug}>
                                                <i className="bx bx-right-arrow-alt"></i> Lire la suite <span></span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </section>
    )
}
export default Blog