import React from "react"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import Banner from "../components/Index/Banner"
import Produits from "../components/Index/Produits"
import NosPartenaires from "../components/Index/NosPartenaires"
import UnProjet from "../components/Common/UnProjet"
import Blog from "../components/Index/Blog"
import Inscription from '../components/Newsletter/Inscription'

const Home = () => {
  return (
    <Layout>
      <Navbar />
      <Banner />
      <div id="produits"></div>
      <Produits />
      <Blog />
      <NosPartenaires />
      <Inscription />
      <UnProjet />
      <Footer />
    </Layout>
  )
}

export default Home