import React from 'react'
import ReactWOW from 'react-wow'
//import { Link } from 'gatsby'
import bannerImg from '../../assets/images/home/img1.png'
import { AnchorLink } from "gatsby-plugin-anchor-links"

const Banner = () => {
    return (
        <div className="it-services-banner">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="banner-wrapper-content">
                            <ReactWOW delay='.1s' animation='fadeInLeft'>
                                <h1>Data Management</h1>
                                <h2>Maîtrisez la conformité, la qualité et la traçabilité de vos données et de vos actions</h2>
                            </ReactWOW>

                            <ReactWOW delay='.1s' animation='fadeInRight'>
                                <div className="btn-box">
                                    <AnchorLink to="/#produits" className="default-btn">
                                        <i className="bx bx-chevron-down"></i> 
                                        Découvrir nos produits <span></span>
                                    </AnchorLink>
                                </div>
                            </ReactWOW>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <ReactWOW delay='.1s' animation='fadeInUp'>
                            <div className="main-banner-image">
                                <img src={bannerImg} alt="banner" />
                            </div>
                        </ReactWOW>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Banner