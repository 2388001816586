import { useStaticQuery, graphql } from "gatsby"

export const useLastThreeBlog = () => {
    const data = useStaticQuery(graphql`
        query {
            lastThree: allMdx (limit: 3, sort: { fields: [frontmatter___date], order: DESC }) {
                nodes {
                id  
                frontmatter {
                    metaTitle
                    metaDescription
                    teaser
                    readTime
                    slug
                    date(formatString: "MMMM YYYY", locale: "fr")
                    author
                    category
                    title
                    hero_image {
                        childImageSharp {
                        gatsbyImageData(
                            formats: [AUTO, WEBP, AVIF]
                        )
                        }
                    }
                }
                }
            }
        }`
    )
    return data
}